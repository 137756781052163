import Vue from "vue";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import { required, email, min, integer, length } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";

/** DEFINE COMPONENTS */
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

/** LOCALIZE */
localize("en", en);

/** CONFIG EACH RULE */
extend("required", {
  ...required,
  message: fieldName => `${fieldName} is required.`
});

extend("email", {
  ...email,
  message: "Email is not valid."
});

extend("min", {
  ...min
});

extend("integer", {
  ...integer,
  message: fieldName => `${fieldName} must be an integer.`
});

extend("length", {
  ...length,
  message: fieldName => `Invalid ${fieldName}.`
});

/** CUSTOM RULES */
extend("phone", {
  message: fieldName => `${fieldName} is not a valid.`,
  validate(value) {
    return new Promise(resolve => {
      const pattern = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
      const valid = pattern.test(value);
      resolve({ valid });
    });
  }
});

extend("password", {
  message() {
    return `Password must be at least 8 characters and needs to include lower and uppercase letters and numbers..`;
  },
  validate(value) {
    return new Promise(resolve => {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
      const valid = pattern.test(value);
      resolve({ valid });
    });
  }
});

extend("passwordMatch", {
  params: ["target"],
  message: "Password confirmation does not match.",
  validate(value, { target }) {
    return value === target;
  }
});

extend("checkboxRequired", {
  message() {
    return `Must be checked.`;
  },
  validate(value) {
    return value !== false;
  }
});

extend("vatNumber", {
  message: fieldName => `${fieldName} is not a valid.`,
  validate(value) {
    return new Promise(resolve => {
      const pattern = /^[0-9]{11}$/;
      const valid = pattern.test(value);
      resolve({ valid });
    });
  }
});

extend("amount", {
  message: fieldName => `${fieldName} is not valid.`,
  validate(value) {
    const amount = Number(value);
    return !isNaN(amount) && amount >= 0;
  }
});
