import services from "../services";
import Utils from "../Utils";
import router from "./../router";

const strict = true;

const getDefaultState = () => {
  return {
    serviceList: [],
    activeService: {},
  };
};

const state = getDefaultState();

const getters = {
  getServiceList:state => state.serviceList,
  getActiveService:state=>state.activeService
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_ACTIVE_SERVICE(state, service) {
    state.activeService = service;
  },
  SET_SERVICE_LIST(state, serviceList) {
    state.serviceList = serviceList;
  },
};

const actions = {
	createService({commit},payload){
		return services.ServicePredict.createService(payload)
			.then((response)=>{
				commit("SET_ACTIVE_SERVICE",response.body);
				return response
			})
			.catch((error) => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
      });
	},
	updateService({commit},service){
		const serviceId = service._id
		return services.ServicePredict.updateService(serviceId,service)
			.then((response)=>{
				commit("SET_ACTIVE_SERVICE",response);
				return response
			})
			.catch((error) => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
      });
	},
  retrieveServices({ commit }) {
    return services.ServicePredict.retrieveServices()
      .then((response) => {
        commit("SET_SERVICE_LIST", response);
        return response;
      })
      .catch((error) => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
      });
  },
	retrieveService({commit},serviceId){
		return services.ServicePredict.retrieveService(serviceId)
			.then((response)=>{
				commit("SET_ACTIVE_SERVICE",response);
				return response
			}).catch((error)=>{
				checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
			})
	},
	deleteService({commit},serviceId){
		return services.ServicePredict.deleteService(serviceId)
			.then(()=>{
				commit("SET_ACTIVE_SERVICE",{});
			}).catch((error)=>{
				checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
			})
	}
};

function checkRedirectionByStatus(status, commit) {
  switch (status) {
    case Utils.ERRORS.UNAUTHORIZED:
      commit("auth/SET_IS_LOGGED_IN", false, { root: true });
      router.replace({ name: "Login" }).catch(() => {});
      break;
    case Utils.ERRORS.NOT_FOUND:
    case Utils.ERRORS.METHOD_NOT_ALLOWED:
      router
        .replace({ name: "Clients", params: { showErrorDialog: true } })
        .catch(() => {});
      break;
    default:
      break;
  }
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions,
};
