/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import router from "./../router/index";
const strict = true;

const getDefaultState = () => {
  return {
    userList: [],
    activeUser: {}
  };
};

const state = getDefaultState();

const getters = {
  getUserList: state => state.userList,
  getActiveUser: state => state.activeUser
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_USER_LIST(state, userList) {
    state.userList = userList;
  },
  SET_ACTIVE_USER(state, user) {
    state.activeUser = user;
  }
};

const actions = {
  retrieveUsers({ commit }) {
    return services.UserService.retrieveUsers()
      .then(response => {
        console.log(response);
        commit("SET_USER_LIST", response);
      })
      .catch(error => {
        commit("SET_USER_LIST", []);
        console.log(error);
        commit(
          "error/SET_ERROR",
          {},
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  retrieveUser({ commit }, userId) {
    return services.UserService.retrieveUser(userId)
      .then(response => {
        console.log(response);
        commit("SET_ACTIVE_USER", response);
      })
      .catch(error => {
        commit("SET_ACTIVE_USER", {});
        console.log(error);
        commit(
          "error/SET_ERROR",
          {},
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  createUser({ commit, getters }, user) {
    return services.UserService.createUser(user)
      .then(response => {
        goToDashboard();
      })
      .catch(error => {
        console.log(error);
        const messageText =
          error.response.body.errorDetails === "Email is not available"
            ? "emailNotAvailable"
            : null;
        commit(
          "error/SET_ERROR",
          { messageText },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  updateUser({ commit, getters }, user) {
    user.password = "some";
    return services.UserService.updateUser(user._id, user)
      .then(response => {
        goToDashboard();
      })
      .catch(error => {
        console.log(error);
        commit(
          "error/SET_ERROR",
          {},
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  deleteUser({ commit, getters }, userId) {
    return services.UserService.deleteUser(userId)
      .then(response => {
        goToDashboard();
      })
      .catch(error => {
        console.log(error);
        commit(
          "error/SET_ERROR",
          {},
          {
            root: true
          }
        );
        return Promise.reject();
      });
  }
};

function goToDashboard() {
  router.push({ name: "Dashboard" });
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
