import Utils from "./Utils";

function getVideoColor(j, trend) {
  const rowOpacity = 0.75;
  const trendOpacity = 0.6;

  switch (j) {
    case 0:
      return trend
        ? `rgba(238, 66, 102, ${trendOpacity})`
        : `rgba(238, 66, 102, ${rowOpacity})`;
    case 1:
      return trend
        ? `rgba(71, 50, 154, ${trendOpacity})`
        : `rgba(71, 50, 154, ${rowOpacity})`;
    case 2:
      return trend
        ? `rgba(137, 137, 128, ${trendOpacity})`
        : `rgba(137, 137, 128, ${rowOpacity})`;
    case 3:
      return trend
        ? `rgba(255, 141, 10, ${trendOpacity})`
        : `rgba(255, 141, 10, ${rowOpacity})`;
    case 4:
      return trend
        ? `rgba(55, 169, 160, ${trendOpacity})`
        : `rgba(55, 169, 160, ${rowOpacity})`;
    case 5:
      return trend
        ? `rgba(120, 89, 100, ${trendOpacity})`
        : `rgba(120, 89, 100, ${rowOpacity})`;
    case 6:
      return trend
        ? `rgba(125, 29, 63, ${trendOpacity})`
        : `rgba(125, 29, 63, ${rowOpacity})`;
    case 7:
      return trend
        ? `rgba(250, 203, 15, ${trendOpacity})`
        : `rgba(250, 203, 15, ${rowOpacity})`;
  }
}

// function isOldJson(analysisData) {
//   let isOld = false;
//   analysisData.videos.forEach(video => {
//     if (video.summaries) {
//       isOld = true;
//     }
//   });
//   return isOld;
// }

// function validateJson(analysisData) {
//   const valid = JsonValidator.validate(analysisData);
//   if (valid) {
//     return null;
//   } else {
//     return JsonValidator.validate.errors;
//   }
// }

// function parseErrors(errors) {
//   console.log(errors);
//   const newErrors = [];

//   if (Array.isArray(errors)) {
//     errors.forEach(error => {
//       const instancePath = error.instancePath;
//       const paths = instancePath.split("/");
//       if (paths.length >= 4) {
//         const index = parseInt(paths[2]);
//         const property = paths[3];

//         newErrors.push({
//           video: index,
//           property
//         });
//       }
//     });
//   }
//   return _.uniqWith(newErrors, _.isEqual);
// }

function createQuestionsArray(analysisData, project) {
  const projectQuestions = project.questions;

  const videoQuestionsArray = analysisData.map(video => {
    const questions = video.segmentsChartData.questions;
    const newQuestions = [];

    questions.forEach((question, index) => {
      if ("id" in question && question.id !== null) {
        const q = projectQuestions[question.id];
        if (q) {
          const answers = [];
          const answerList = q.answers.answerList;
          // If there are answers for this question (Non-scale type)
          if (q.type !== "Scale") {
            answerList.forEach((answer, index) => {
              answers.push({
                text: answer,
                count: question.styles["answer" + index].count || 0
              });
            });
          } else if (q.type === "Scale") {
            // Question type is cale
            [1, 2, 3, 4, 5].forEach((answer, index) => {
              answers.push({
                text: answer,
                count: question.styles["answer" + index].count || 0
              });
            });
          }

          newQuestions.push({
            text: q.text || "question" + index,
            answers
          });
        } else {
          // const answers = [];
          // let answerIndex = 0;
          // Object.keys(question.styles).forEach((key, i) => {
          //   if (key.includes("trend")) return;
          //   answers.push(question.styles[key].text || "answer" + answerIndex);
          //   answerIndex++;
          // });
          // newQuestions.push({
          //   text: question.text || "question" + index,
          //   answers: answers || []
          // });
          // There are extra questions in the json
          // TODO: HANDLE extra questions
        }
      } else {
        // No id in the json --> custom question coming from the json
        // Take the answers from the json
        const answers = [];
        let answerIndex = 0;
        Object.keys(question.styles).forEach((key, i) => {
          if (key.includes("trend")) return;
          answers.push({
            text: question.styles[key].text || "answer" + answerIndex,
            count: question.styles[key].count || 0
          });
          answerIndex++;
        });
        newQuestions.push({
          text: question.text || "question" + index,
          answers: answers || []
        });
      }
    });
    return newQuestions;
  });
  return videoQuestionsArray;
}

function createGenderInfoArray(analysisData) {
  const genderInfoArray = analysisData.map(video => {
    const genderInfo = [];
    const genderStyles = video.demographicsChartData.gender.styles;
    Object.keys(genderStyles).forEach(key => {
      if (key.includes("trend")) return;
      genderInfo.push({
        text: key,
        count: genderStyles[key].count || 0
      });
    });
    return genderInfo;
  });
  return genderInfoArray;
}

function createAgeRangesArray(analysisData) {
  const ageRangesArray = analysisData.map(video => {
    const ageRanges = [];
    const ageRangeStyles = video.demographicsChartData.age.styles;
    let ageRangeIndex = 0;
    Object.keys(ageRangeStyles).forEach(key => {
      if (key.includes("trend")) return;

      ageRanges.push({
        text: ageRangeStyles[key].text || "ageRange" + ageRangeIndex,
        count: ageRangeStyles[key].count || 0
      });
      ageRangeIndex++;
    });
    return ageRanges;
  });
  return ageRangesArray;
}

function createCustomSegments(analysisData) {
  const customSegmentsArray = analysisData.map(video => {
    const customSegments = video.demographicsChartData.custom;
    const parsedSegments = [];

    if (customSegments) {
      customSegments.forEach((segment, index) => {
        const values = [];
        let valueIndex = 0;
        Object.keys(segment.styles).forEach((key, i) => {
          if (key.includes("trend")) return;
          values.push({
            text: segment.styles[key].text || "value" + valueIndex,
            count: segment.styles[key].count || 0
          });
          valueIndex++;
        });
        parsedSegments.push({
          text: segment.text || "customSegment" + index,
          values: values || []
        });
      });
      return parsedSegments;
    }
  });
  return customSegmentsArray.filter(segments => segments);
}

function createComparisonData(analysisData) {
  const emotionCharts = analysisData
    .map(videoItem => videoItem.emotionChartData)
    .map(emotionChartData => emotionChartData.data);

  const data = {
    happiness: [],
    sadness: [],
    fear: [],
    disgust: [],
    surprise: [],
    anger: []
  };
  const styles = {};

  emotionCharts.forEach((video, i) => {
    styles["video" + i] = {
      color: getVideoColor(i),
      strokeWidth: 1.7,
      tension: 0.3
    };

    styles["video" + i + "-trend"] = {
      color: getVideoColor(i, true),
      strokeWidth: 1.5,
      tension: 0.3,
      borderDash: [10, 5]
    };
  });

  const videosCount = emotionCharts.length;

  let maxLength = emotionCharts[0].length;
  let maxIndex = 0;

  emotionCharts.forEach((video, index) => {
    if (video.length > maxLength) {
      maxLength = video.length;
      maxIndex = index;
    }
  });

  for (let i = 0; i < maxLength; i++) {
    data.happiness.push({ time: emotionCharts[maxIndex][i].time });
    data.sadness.push({ time: emotionCharts[maxIndex][i].time });
    data.fear.push({ time: emotionCharts[maxIndex][i].time });
    data.disgust.push({ time: emotionCharts[maxIndex][i].time });
    data.surprise.push({ time: emotionCharts[maxIndex][i].time });
    data.anger.push({ time: emotionCharts[maxIndex][i].time });
  }

  for (let i = 0; i < maxLength; i++) {
    for (let j = 0; j < videosCount; j++) {
      if (!emotionCharts[j][i]) {
        continue;
      }
      data.happiness[i]["video" + j] = emotionCharts[j][i].happiness;
      data.sadness[i]["video" + j] = emotionCharts[j][i].sadness;
      data.fear[i]["video" + j] = emotionCharts[j][i].fear;
      data.disgust[i]["video" + j] = emotionCharts[j][i].disgust;
      data.surprise[i]["video" + j] = emotionCharts[j][i].surprise;
      data.anger[i]["video" + j] = emotionCharts[j][i].anger;
      data.happiness[i]["video" + j + "-trend"] =
        emotionCharts[j][i]["happiness-trend"];
      data.sadness[i]["video" + j + "-trend"] =
        emotionCharts[j][i]["sadness-trend"];
      data.fear[i]["video" + j + "-trend"] = emotionCharts[j][i]["fear-trend"];
      data.disgust[i]["video" + j + "-trend"] =
        emotionCharts[j][i]["disgust-trend"];
      data.surprise[i]["video" + j + "-trend"] =
        emotionCharts[j][i]["surprise-trend"];
      data.anger[i]["video" + j + "-trend"] =
        emotionCharts[j][i]["anger-trend"];
    }
  }

  return { styles, data };
}

function parseJsonVersion(jsonVersion) {
  if (jsonVersion) {
    const versionMajor = jsonVersion.split(".")[0];
    return parseInt(versionMajor);
  } else {
    return -1;
  }
}

function prepareData(store, analysisDataRaw, project, projectAssets, clientId) {
  // TODO: Check if the videos in json are less or more than the project asset

  const jsonVersion = parseJsonVersion(analysisDataRaw.json_version);
  const analysisData = analysisDataRaw.videos;

  const fullData = [];

  let country = "-";
  const entry = Utils.COUNTRY_LIST.find(
    countryEntry => countryEntry.code === project.audience.country
  );

  if (entry) {
    country = entry.name;
  }

  const c = store.rootGetters["clients/getClientList"].find(
    client => client._id === clientId
  );

  let client = "-";

  if (c) {
    client = c.companyName;
  }

  projectAssets.forEach((asset, index) => {
    const src = asset.src.replace(/\+/g, "%2B");
    const category = asset.category;
    const name = asset.name;

    fullData.push({
      src,
      category,
      name,
      client,
      country,
      ...analysisData[index]
    });
  });

  fullData.sort((a, b) => {
    if (a.rank && b.rank) {
      return a.rank - b.rank;
    } else if (a.rank && !b.rank) {
      return -1;
    } else {
      return 1;
    }
  });

  const comparisonData = createComparisonData(fullData);
  const questionsArray = createQuestionsArray(fullData, project);

  const ageRangesArray = createAgeRangesArray(fullData);
  const genderInfoArray = createGenderInfoArray(fullData);
  const customSegments = createCustomSegments(fullData);

  store.commit("SET_JSON_VERSION", jsonVersion);
  store.commit("SET_COMPARISON_DATA", comparisonData);
  store.commit("SET_QUESTIONS", questionsArray);
  store.commit("SET_AGE_RANGES", ageRangesArray);
  store.commit("SET_GENDER_INFO", genderInfoArray);
  store.commit("SET_CUSTOM_SEGMENTS", customSegments);
  store.commit("SET_PROJECT_QUESTIONS", project.questions);
  store.commit("SET_PROJECT_NAME", project.name);
  store.commit("SET_VIDEO_DATA", fullData);
}

export const prepareAndCommitData = (
  store,
  analysisDataRaw,
  project,
  projectAssets
) => {
  store.commit("clients/SET_ACTIVE_PROJECT", project, { root: true });

  if (projectAssets.length > analysisDataRaw.videos.length) {
    projectAssets = [...projectAssets.slice(0, analysisDataRaw.videos.length)];
    store.commit(
      "error/SET_ERROR",
      {
        titleText: "dialog.error.projectAnalysis.missingData.title",
        messageText: "dialog.error.projectAnalysis.missingData.message"
      },
      { root: true }
    );
  }

  prepareData(store, analysisDataRaw, project, projectAssets);
};

export default prepareAndCommitData;
