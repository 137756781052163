import UserService from "./services/UserService";
import ClientService from "./services/ClientService";
import EmployeeService from "./services/EmployeeService";
import BillingService from "./services/BillingService";
import ProjectService from "./services/ProjectService";
import ServicePredict from "./services/ServicePredictService";
import SubscriptionPredict from "./services/SubscriptionPredictService";
export default {
  UserService: new UserService(),
  ClientService: new ClientService(),
  EmployeeService: new EmployeeService(),
  BillingService: new BillingService(),
  ProjectService: new ProjectService(),
  ServicePredict: new ServicePredict(),
  SubscriptionPredict: new SubscriptionPredict(),
};
