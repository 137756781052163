const strict = true;

const getDefaultState = () => {
  return {
    active: false,
    message: {
      text: "",
      params: null
    }
  };
};

const state = getDefaultState();

const getters = {
  isActive: state => state.active,
  getMessageText: state => state.message.text,
  getMessageParams: state => state.message.params
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_ACTIVE(state, active) {
    state.active = active;
  },
  SET_LOADER(state, { messageText = null, messageParams = null }) {
    state.active = true;
    state.message.text = messageText;
    state.message.params = messageParams;
  }
};

const actions = {
  close({ commit, getters }) {
    commit("RESET");
  }
};

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
