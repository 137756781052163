/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import router from "./../router";
import services from "./../services";
import Utils from "../Utils";
const strict = true;

const getDefaultState = () => {
  return {
    isLoggedIn: false,
    employeeId: null,
    sessionToken: null,
    employeeAccount: {}
  };
};

const state = getDefaultState();

const getters = {
  isLoggedIn: state => state.isLoggedIn,
  getEmployeeId: state => state.employeeId,
  getSessionToken: state => state.sessionToken,
  getEmployeeAccount: state => state.employeeAccount
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_IS_LOGGED_IN(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },
  SET_EMPLOYEE_ID(state, employeeId) {
    state.employeeId = employeeId;
  },
  SET_SESSION_TOKEN(state, sessionToken) {
    state.sessionToken = "Bearer " + sessionToken;
  },
  SET_EMPLOYEE_ACCOUNT(state, employeeAccount) {
    state.employeeAccount = employeeAccount;
  }
};

const actions = {
  login({ commit }, { email, password }) {
    const userInfo = {
      email,
      password,
      domain: "emotiva"
    };
    return services.UserService.loginUser(userInfo)
      .then(response => {
        commit("SET_IS_LOGGED_IN", true);
        commit("SET_EMPLOYEE_ID", response.accountId);
        commit("SET_SESSION_TOKEN", response.token);
        router.replace({ name: "Dashboard" });
      })
      .catch(error => {
        console.log(error);
        commit(
          "error/SET_ERROR",
          {
            titleText: "dialog.error.loginTitle",
            messageText: "dialog.error.loginMessage"
          },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  retrieveActiveEmployeeAccountInfo({ commit, getters }) {
    const emotivaEmployeeId = getters.getEmployeeId;
    return services.EmployeeService.retrieveEmotivaEmployee(emotivaEmployeeId)
      .then(response => {
        commit("SET_EMPLOYEE_ACCOUNT", response);
      })
      .catch(error => {
        console.log(error);
        commit("SET_EMPLOYEE_ACCOUNT", {});
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  updateEmployeeAccount({ commit, getters }) {
    const employeeId = getters.getEmployeeId;
    const employee = getters.getEmployeeAccount;
    delete employee.registration;
    return services.EmployeeService.updateEmotivaEmployee(employeeId, employee)
      .then(() => {})
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  confirmUser({ commit }, { password, token }) {
    return services.UserService.confirmUser({ password, token })
      .then(() => {})
      .catch(error => {
        console.log(error);
        commit(
          "error/SET_ERROR",
          {},
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  recoverUserPassword({ commit }, { email }) {
    return services.UserService.recoverUserPassword({
      email,
      type: "emotiva"
    })
      .then(() => {})
      .catch(error => {
        console.log(error);
        commit(
          "error/SET_ERROR",
          {},
          {
            root: true
          }
        );
        return Promise.reject();
      });
  }
};

function checkRedirectionByStatus(status, commit) {
  switch (status) {
    case Utils.ERRORS.UNAUTHORIZED:
      commit("auth/SET_IS_LOGGED_IN", false, { root: true });
      router.replace({ name: "Login" }).catch(() => {});
      break;
    case Utils.ERRORS.NOT_FOUND:
    case Utils.ERRORS.METHOD_NOT_ALLOWED:
      router
        .replace({ name: "Dashboard", params: { showErrorDialog: true } })
        .catch(() => {});
      break;
    default:
      break;
  }
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
