import SwaggerClient from "swagger-client";
import store from "./../store";
import Config from "./Config";

import i18n from "../i18n";

const proxyURL = Config.value("backendHost");
console.log(proxyURL);

const buildRequestBody = function(requestBody) {
  return {
    requestBody: requestBody,
    server: "{protocol}://{host}:{port}{basePath}{apiPrefix}",
    serverVariables: {
      protocol: Config.value("backendProtocol"),
      host: Config.value("backendHost"),
      port: Config.value("backendPort"),
      basePath: Config.value("backendBasePath"),
      apiPrefix: Config.value("backendPrefix")
    }
  };
};

const swagger = new SwaggerClient("/emotiva.v1.json", {
  requestInterceptor: request => {
    const sessionToken = store && store.getters["auth/getSessionToken"];
    if (sessionToken) request.headers.Authorization = sessionToken;
    request.headers["Accept-Language"] = i18n.locale;

    return request;
  },
  responseInterceptor: () => {}
});

export default { swagger, buildRequestBody };
