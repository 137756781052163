import swaggerClient from "./../plugins/swagger-client";

export default class EmployeeService {
  retrieveEmotivaEmployees() {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["emotiva employees"].retrieveEmotivaEmployees(
          {},
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveEmotivaEmployee(emotivaEmployeeId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["emotiva employees"].retrieveEmotivaEmployee(
          { emotivaEmployeeId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createUser(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["emotiva employees"].createUser(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateEmotivaEmployee(emotivaEmployeeId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["emotiva employees"].updateEmotivaEmployee(
          { emotivaEmployeeId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteEmotivaEmployee(emotivaEmployeeId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis["emotiva employees"].deleteEmotivaEmployee(
          { emotivaEmployeeId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
