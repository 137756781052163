import swaggerClient from "./../plugins/swagger-client";

export default class ClientService {
  retrieveClients(clientType) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.clients.retrieveClients(
          {clientType},
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveClient(clientId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.clients.retrieveClient(
          { clientId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createClient(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.clients.createClient(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateClient(clientId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.clients.updateClient(
          { clientId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteClient(clientId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.clients.deleteClient(
          { clientId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveProjects(clientId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.retrieveProjects(
          { clientId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveProject(clientId, projectId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.retrieveProject(
          { clientId, projectId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateProject(clientId, projectId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.projects.updateProject(
          { clientId, projectId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
