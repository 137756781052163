import swaggerClient from "../plugins/swagger-client";

export default class SubscriptionPredict {
  createSubscription(payload) {
    return swaggerClient.swagger.then((client) => {
      return client.apis.subscriptions
        .createSubscription({}, swaggerClient.buildRequestBody(payload), {})
        .then((response) => {
          return response.body;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    });
  }

  retrieveSubscriptions() {
    return swaggerClient.swagger.then((client) => {
      return client.apis.subscriptions
        .retrieveSubscriptions({}, swaggerClient.buildRequestBody(), {})
        .then((response) => {
          return response.body;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    });
  }

  retrieveSubscription(subscriptionId) {
    return swaggerClient.swagger.then((client) => {
      return client.apis.subscriptions
        .retrieveSubscription({ subscriptionId }, swaggerClient.buildRequestBody(), {})
        .then((response) => {
          return response.body;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    });
  }

  updateSubscription(subscriptionId, payload) {
    return swaggerClient.swagger
      .then((client) => {
        return client.apis.subscriptions.updateSubscription(
          { subscriptionId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
	
  deleteSubscription(subscriptionId) {
    return swaggerClient.swagger.then((client) => {
      return client.apis.subscriptions
        .deleteSubscription({ subscriptionId }, swaggerClient.buildRequestBody(), {})
        .then((response) => {
          return response.body;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    });
  }
}
