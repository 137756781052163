import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import router from "./router/index";

import success from "./store/success";
import error from "./store/error";
import confirm from "./store/confirm";
import loader from "./store/loader";
import auth from "./store/auth";
import users from "./store/users";
import clients from "./store/clients";
import employees from "./store/employees";
import billing from "./store/billing";
import projects from "./store/projects";
import analysis from "./store/analysis";
import subscriptionPredict from "./store/subscriptionPredict"
import servicePredict from "./store/servicePredict"
Vue.use(Vuex);
// const debug = process.env.NODE_ENV !== "production";
// Vue.config.devtools = true;

const storeModules = {
  success,
  error,
  confirm,
  loader,
  auth,
  users,
  clients,
  employees,
  billing,
  projects,
  analysis,
  subscriptionPredict,
  servicePredict
};

const storeModuleKeysList = Object.keys(storeModules);

/****
 * Setup persistence
 * - used 'localStorage' in order to pesist the store,
 * - persist only the 'storeModulePersistList' modules,
 * - state reducer. reduces state to only those values you want to save.
 *   For example, save entire 'analysis' module, except 'videoData'(=== null).
 */

const vuexSecure = new VuexPersistence({
  storage: window.localStorage,
  modules: storeModuleKeysList,
  reducer: state => {
    const persistStoreModules = storeModuleKeysList
      .filter(key => key !== "analysis")
      .reduce((acc, cur) => {
        if (cur) acc[cur] = state[cur];
        return acc;
      }, {});
    return {
      ...persistStoreModules,
      clients: { ...state.clients, activeClientProjectList: [] }
    };
  }
});

const actions = {
  clearAll({ commit }) {
    Object.keys(storeModules).forEach(s => {
      commit(`${s}/RESET`);
    });
    window.sessionStorage.clear();
    window.localStorage.clear();
  },
  async logout({ commit }) {
    commit("auth/SET_IS_LOGGED_IN", false);
    router.replace({ name: "Login" });
  }
};

export default new Vuex.Store({
  modules: storeModules,
  strict: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
  plugins: [vuexSecure.plugin]
});
