import swaggerClient from "../plugins/swagger-client";

export default class ServicePredict {
  createService(payload) {
    return swaggerClient.swagger.then((client) => {
      return client.apis.services
        .createService({}, swaggerClient.buildRequestBody(payload), {})
        .then((response) => {
          return response.body;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    });
  }

  retrieveServices() {
    return swaggerClient.swagger.then((client) => {
      return client.apis.services
        .retrieveServices({}, swaggerClient.buildRequestBody(), {})
        .then((response) => {
          return response.body;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    });
  }

  retrieveService(serviceId) {
    return swaggerClient.swagger.then((client) => {
      return client.apis.services
        .retrieveService({ serviceId }, swaggerClient.buildRequestBody(), {})
        .then((response) => {
          return response.body;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    });
  }

  updateService(serviceId, payload) {
    return swaggerClient.swagger
      .then((client) => {
        return client.apis.services.updateService(
          { serviceId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
	
  deleteService(serviceId) {
    return swaggerClient.swagger.then((client) => {
      return client.apis.services
        .deleteService({ serviceId }, swaggerClient.buildRequestBody(), {})
        .then((response) => {
          return response.body;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    });
  }
}
