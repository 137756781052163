import swaggerClient from "./../plugins/swagger-client";

export default class BillingService {
  // retrieveInvoices(clientId) {
  //   console.log(clientId);
  //   return Promise.resolve([
  //     {
  //       _id: 1,
  //       invoiceNumber: "EA06504J-001",
  //       amount: 1000,
  //       date: "12/2/2001",
  //       itemsPurchased: 12
  //     },
  //     {
  //       _id: 2,
  //       invoiceNumber: "EA06504J-002",
  //       amount: 1050,
  //       date: "12/2/2011",
  //       itemsPurchased: 12
  //     },
  //     {
  //       _id: 3,
  //       invoiceNumber: "EA06504J-003",
  //       amount: 990,
  //       date: "12/12/2001",
  //       itemsPurchased: 22
  //     },
  //     {
  //       _id: 4,
  //       invoiceNumber: "EA06504J-004",
  //       amount: 1900,
  //       date: "2/2/2020",
  //       itemsPurchased: 12
  //     },
  //     {
  //       _id: 5,
  //       invoiceNumber: "EA06504J-005",
  //       amount: 2000,
  //       date: "12/5/2010",
  //       itemsPurchased: 12
  //     },
  //     {
  //       _id: 6,
  //       invoiceNumber: "EA06504J-006",
  //       amount: 2300,
  //       date: "12/11/2019",
  //       itemsPurchased: 12
  //     }
  //   ]);
  // }

  retrieveInvoices(clientId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.invoices.retrieveInvoices(
          { clientId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createInvoice(clientId, payload) {
    // return Promise.resolve(payload);
    return swaggerClient.swagger
      .then(client => {
        return client.apis.invoices.createInvoice(
          { clientId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateInvoice(clientId, invoiceId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.invoices.updateInvoice(
          { clientId, invoiceId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteInvoice(clientId, invoiceId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.invoices.deleteInvoice(
          { clientId, invoiceId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
