/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import router from "./../router/index";
import Utils from "./../Utils";
const strict = true;

const getDefaultState = () => {
  return {
    employeeList: [],
    activeEmployee: {}
  };
};

const state = getDefaultState();

const getters = {
  getEmployeeList: state => state.employeeList,
  getActiveEmployee: state => state.activeEmployee
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_EMPLOYEE_LIST(state, employeeList) {
    state.employeeList = employeeList;
  },
  SET_ACTIVE_EMPLOYEE(state, employee) {
    state.activeEmployee = employee;
  }
};

const actions = {
  retrieveEmotivaEmployees({ commit }) {
    return services.EmployeeService.retrieveEmotivaEmployees()
      .then(response => {
        commit("SET_EMPLOYEE_LIST", response);
      })
      .catch(error => {
        console.log(error);
        commit("SET_EMPLOYEE_LIST", []);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  retrieveEmotivaEmployee({ commit }, employeeId) {
    return services.EmployeeService.retrieveEmotivaEmployee(employeeId)
      .then(response => {
        commit("SET_ACTIVE_EMPLOYEE", response);
      })
      .catch(error => {
        console.log(error);
        commit("SET_ACTIVE_EMPLOYEE", {});
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  createEmployee({ commit, getters }, employee) {
    return services.UserService.createUser(employee)
      .then(response => {
        goToUsersPage();
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  updateEmployee({ commit, getters }, employee) {
    delete employee.registration;
    return services.EmployeeService.updateEmotivaEmployee(
      employee._id,
      employee
    )
      .then(response => {
        goToUsersPage();
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  deleteEmployee({ commit, getters }, employeeId) {
    return services.EmployeeService.deleteEmotivaEmployee(employeeId)
      .then(response => {
        goToUsersPage();
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  recoverUserPassword({ commit }, { email }) {
    return services.UserService.recoverUserPassword({
      email,
      type: "emotiva"
    })
      .then(() => {})
      .catch(error => {
        console.log(error);
        commit(
          "error/SET_ERROR",
          {},
          {
            root: true
          }
        );
        return Promise.reject();
      });
  }
};

function checkRedirectionByStatus(status, commit) {
  switch (status) {
    case Utils.ERRORS.UNAUTHORIZED:
      commit("auth/SET_IS_LOGGED_IN", false, { root: true });
      router.replace({ name: "Login" }).catch(() => {});
      break;
    case Utils.ERRORS.NOT_FOUND:
    case Utils.ERRORS.METHOD_NOT_ALLOWED:
      router
        .replace({ name: "Dashboard", params: { showErrorDialog: true } })
        .catch(() => {});
      break;
    default:
      break;
  }
}

function goToUsersPage() {
  router.push({ name: "Dashboard" });
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
