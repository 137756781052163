/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import router from "./../router/index";
import dayjs from "dayjs";
import i18n from "./../i18n";
import Utils from "./../Utils";

const strict = true;

const getDefaultState = () => {
  return {
    invoiceTypeOptions: [
      {
        text: i18n.t("page.clientInvoices.invoiceTypeOptions.subscription"),
        value: "subscription"
      },
      {
        text: i18n.t("page.clientInvoices.invoiceTypeOptions.custom"),
        value: "custom"
      }
    ],
    invoiceList: []
  };
};

const state = getDefaultState();

const getters = {
  getInvoiceList: state => state.invoiceList,
  getInvoiceTypeOptions: state => state.invoiceTypeOptions
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_INVOICE_LIST(state, invoiceList) {
    state.invoiceList = invoiceList;
  },
  ADD_INVOICE(state, invoice) {
    state.invoiceList.push(invoice);
  },
  UDATE_INVOICE(state, invoice) {
    const index = state.invoiceList.findIndex(i => i._id === invoice._id);
    if (index > -1) state.invoiceList[index] = { ...invoice };
  },
  REMOVE_INVOICE(state, invoiceId) {
    state.invoiceList = state.invoiceList.filter(i => {
      return i._id !== invoiceId;
    });
  }
};

const actions = {
  retrieveInvoices({ commit }, clientId) {
    return services.BillingService.retrieveInvoices(clientId)
      .then(response => {
        commit("SET_INVOICE_LIST", response);
      })
      .catch(error => {
        console.log(error);
        commit("SET_INVOICE_LIST", []);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  createInvoice({ commit, dispatch }, { clientId, invoice }) {
    const invoiceDate = dayjs(invoice.date).format("YYYY-MM-DD");
    const payload = {
      number: invoice.number,
      // date: dayjs(invoice.date).toISOString(),
      date: dayjs(`${invoiceDate}T00:00:00.00Z`),
      itemsPurchased: invoice.itemsPurchased,
      type: invoice.type,
      amount: invoice.amount,
      branchId: invoice.branchId
    };
    return services.BillingService.createInvoice(clientId, payload)
      .then(response => {
        // commit("ADD_INVOICE", response);
      })
      .then(() => {
        return dispatch("retrieveInvoices", clientId);
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  updateInvoice({ commit, dispatch }, { clientId, invoice }) {
    const invoiceDate = dayjs(invoice.date).format("YYYY-MM-DD");
    const payload = {
      number: invoice.number,
      // date: dayjs(invoice.date).toISOString(),
      date: dayjs(`${invoiceDate}T00:00:00.00Z`),
      itemsPurchased: invoice.itemsPurchased,
      type: invoice.type,
      amount: invoice.amount
    };
    const invoiceId = invoice._id;
    return services.BillingService.updateInvoice(clientId, invoiceId, payload)
      .then(response => {
        // commit("UDATE_INVOICE", response);
      })
      .then(() => {
        return dispatch("retrieveInvoices", clientId);
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  deleteInvoice({ commit, dispatch }, { clientId, invoiceId }) {
    return services.BillingService.deleteInvoice(clientId, invoiceId)
      .then(response => {
        commit("REMOVE_INVOICE", invoiceId);
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  }
};

function checkRedirectionByStatus(status, commit) {
  switch (status) {
    case Utils.ERRORS.UNAUTHORIZED:
      commit("auth/SET_IS_LOGGED_IN", false, { root: true });
      router.replace({ name: "Login" }).catch(() => {});
      break;
    case Utils.ERRORS.NOT_FOUND:
    case Utils.ERRORS.METHOD_NOT_ALLOWED:
      router
        .replace({ name: "Clients", params: { showErrorDialog: true } })
        .catch(() => {});
      break;
    default:
      break;
  }
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
