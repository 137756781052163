<template>
  <div>
    <div id="app" class="bg-light min-vh-100">
      <router-view />
    </div>

    <base-loader-dialog />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "assets/custom-bootstrap-vars.scss";
@import "assets/custom-fonts.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
</style>

<style>
.clickable {
  cursor: pointer;
}
</style>

<style scoped>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
</style>

<style>
* {
  font-family: Roboto !important;
}

.link {
  text-decoration: underline;
}
.button--dark-border {
  border: 1px solid #ced4da;
}
.text__xs {
  font-size: 12px;
}
.bg-blue-primary {
  background-color: #033051;
}
.title__1 {
  /* font-family: ; */
  font-size: 1.2rem;
  font-weight: 700;
}
.title__2 {
  /* font-family: ; */
  font-size: 1.2rem;
  font-weight: 400;
}
.title__3 {
  /* font-family: ; */
  font-size: 0.8rem;
  font-weight: 700;
}
.subtitle__1 {
  /* font-family: ; */
  font-size: 0.8rem;
  font-weight: 400;
}
.body__1 {
  font-size: 14px;
  font-weight: 500;
}
.body__2 {
  font-size: 14px;
  font-weight: 400;
}
.btn-secondary {
  background: #dddddd;
  border: unset;
  color: #6c757d;
  font-weight: 400;
}

.btn-outline-gray-dark,
.btn-outline-light {
  border-color: #c2c2c2;
}
.btn-outline-gray-medium {
  border-color: rgb(219, 219, 219);
  color: rgb(219, 219, 219);
}
.btn-outline-gray-medium:hover,
.btn-outline-gray-medium.active {
  color: white !important;
}
</style>
