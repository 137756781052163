// import router from "./../router";
import Utils from "./../Utils";
const strict = true;

const getDefaultState = () => {
  return {
    active: false,
    title: {
      text: "",
      params: null
    },
    message: {
      text: "",
      params: null
    },
    actionBtn: {
      label: "",
      callback: null
    }
  };
};

const state = getDefaultState();

const getters = {
  isActive: state => state.active,
  getTitleText: state => state.title.text,
  getTitleParams: state => state.title.params,
  getMessageText: state => state.message.text,
  getMessageParams: state => state.message.params,
  getActionBtnLabel: state => state.actionBtn.label,
  getActionBtnCallback: state => state.actionBtn.callback
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_ACTIVE(state, active) {
    state.active = active;
  },
  SET_ERROR(
    state,
    {
      status = 0,
      titleText = null,
      titleParams = null,
      messageText = null,
      messageParams = null,
      actionBtnLabel = "button.close",
      actionBtnCallback = null
    }
  ) {
    state.active = true;

    if (titleText === null) state.title = getDefaultTitleByStatus(status);
    else {
      state.title.text = titleText;
      state.title.params = titleParams;
    }
    if (messageText === null) state.message = getDefaultMessageByStatus(status);
    else {
      state.message.text = messageText;
      state.message.params = messageParams;
    }

    state.actionBtn.label = actionBtnLabel;
    state.actionBtn.callback = actionBtnCallback;
  }
};

const actions = {
  close({ commit, getters }) {
    const callbackFunction = getters.getActionBtnCallback;
    if (Utils.isFunction(callbackFunction)) callbackFunction();

    commit("RESET");
  }
};

function getDefaultTitleByStatus(status) {
  switch (status) {
    case 401: // Authorization
      return {
        text: "dialog.error.unauthorized.title",
        params: null
      };
    default:
      return {
        text: "dialog.error.defaultTitle",
        params: null
      };
  }
}
function getDefaultMessageByStatus(status) {
  switch (status) {
    case 401: // Authorization
      return {
        text: "dialog.error.unauthorized.message",
        params: null
      };
    default:
      return {
        text: "dialog.error.defaultMessage",
        params: null
      };
  }
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
