/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import router from "./../router/index";
import cloneDeep from "lodash/cloneDeep";
import Utils from "./../Utils";
const strict = true;

const getDefaultState = () => {
  return {
    clientList: [],
    activeClient: {},
    activeClientUserList: [],
    activeClientProjectList: [],
    activeProject: {}
  };
};

const state = getDefaultState();

const getters = {
  getClientList: state => state.clientList,
  getActiveClient: state => state.activeClient,
  getActiveClientUserList: state => state.activeClientUserList,
  getActiveClientProjectList: state => state.activeClientProjectList,
  getActiveProject: state => state.activeProject
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_CLIENT_LIST(state, clientList) {
    state.clientList = clientList;
  },
  SET_ACTIVE_CLIENT(state, client) {
    state.activeClient = client;
  },
  SET_ACTIVE_CLIENT_USER_LIST(state, userList) {
    state.activeClientUserList = userList;
  },
  SET_ACTIVE_CLIENT_PROJECT_LIST(state, projectList) {
    state.activeClientProjectList = projectList;
  },
  SET_ACTIVE_PROJECT(state, project) {
    state.activeProject = project;
  }
};

const actions = {
  retrieveClients({ commit },clientType) {
    return services.ClientService.retrieveClients(clientType)
      .then(response => {
        commit("SET_CLIENT_LIST", response);
      })
      .catch(error => {
        console.log(error);
        commit("SET_CLIENT_LIST", []);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  retrieveClientsByType({ commit },type) {
    return services.ClientService.retrieveClients(type)
      .then(response => {
        const predictClients = response.filter(client => client.type === 'predict');
        commit("SET_CLIENT_LIST", predictClients);
      })
      .catch(error => {
        console.log(error);
        commit("SET_CLIENT_LIST", []);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  retrieveClient({ commit, dispatch, getters }, clientId) {
    return services.ClientService.retrieveClient(clientId)
      .then((response) => {
        commit("SET_ACTIVE_CLIENT", response);
      })
      .then(() => {
        const activeClient = getters.getActiveClient;
        if (activeClient.multiUser) {
          return dispatch("retrieveClientUsersByClientId", activeClient._id);
        }
      })
      .catch((error) => {
        console.log(error);
        commit("SET_ACTIVE_CLIENT", {});
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  retrieveClientUsersByClientId({ commit }, clientId) {
    return services.UserService.retrieveUsers({clientId})
      .then(response => {
        commit("SET_ACTIVE_CLIENT_USER_LIST", response);
      })
      .catch(error => {
        console.log(error);
        commit("SET_ACTIVE_CLIENT_USER_LIST", []);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  createClient({ commit, getters }, client) {
    client.password = "CiaoCiao8"; // dummy password
    return services.UserService.createUser(client)
      .then(response => {
        goToClientsPage();
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        const messageText =
          error.response.body.errorDetails === "Email is not available"
            ? "emailNotAvailable"
            : null;
        commit(
          "error/SET_ERROR",
          { status: error.status, messageText },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  updateClient({ commit, dispatch, getters }, client) {
    delete client.registration;
    return services.ClientService.updateClient(client._id, client)
      .then((response) => {
        // goToClientsPage(); // Is this needed anymore?
        commit("SET_ACTIVE_CLIENT", response);
      })
      .then(() => {
        const activeClient = getters.getActiveClient;
        if (activeClient.multiUser) {
          return dispatch("retrieveClientUsersByClientId", activeClient._id);
        }
      })
      .catch((error) => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  deleteClient({ commit, getters }, clientId) {
    return services.ClientService.deleteClient(clientId)
      .then(response => {
        goToClientsPage();
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  retrieveProjectsByClientId({ commit }, clientId) {
    return services.ClientService.retrieveProjects(clientId)
      .then(response => {
        commit("SET_ACTIVE_CLIENT_PROJECT_LIST", response);
      })
      .catch(error => {
        console.log(error);
        commit("SET_ACTIVE_CLIENT_PROJECT_LIST", []);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  retrieveProject({ commit }, { clientId, projectId }) {
    return services.ClientService.retrieveProject(clientId, projectId)
      .then(response => {
        commit("SET_ACTIVE_PROJECT", response);
      })
      .catch(error => {
        console.log(error);
        commit("SET_ACTIVE_PROJECT", {});
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },

  hideMetrics({ commit, getters }, hide) {
    const project = cloneDeep(getters.getActiveProject);
    project.step = hide ? -1 : 0;
    console.log(project);
    const clientId = project.clientId;
    const projectId = project._id;

    return services.ClientService.updateProject(clientId, projectId, project)
      .then(response => {
        commit("SET_ACTIVE_PROJECT", response);
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },

  closeProject({ commit, getters }) {
    const project = cloneDeep(getters.getActiveProject);
    project.status = "closed";
    const clientId = project.clientId;
    const projectId = project._id;

    return services.ClientService.updateProject(clientId, projectId, project)
      .then(response => {
        commit("SET_ACTIVE_PROJECT", response);
      })
      .catch(error => {
        console.log(error);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  }
};

function checkRedirectionByStatus(status, commit) {
  switch (status) {
    case Utils.ERRORS.UNAUTHORIZED:
      commit("auth/SET_IS_LOGGED_IN", false, { root: true });
      router.replace({ name: "Login" }).catch(() => {});
      break;
    case Utils.ERRORS.NOT_FOUND:
    case Utils.ERRORS.METHOD_NOT_ALLOWED:
      router
        .replace({ name: "Clients", params: { showErrorDialog: true } })
        .catch(() => {});
      break;
    default:
      break;
  }
}

function goToClientsPage() {
  router.push({ name: "Clients" });
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
