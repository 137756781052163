import services from "../services";
import Utils from "../Utils";
import router from "./../router";

const strict = true;

const getDefaultState = () => {
	return {
		subscriptionList: [],
		activeSubscription: {},
	};
};

const state = getDefaultState();

const getters = {
	getSubscriptionList: state => state.subscriptionList,
	getActiveSubscription: state => state.activeSubscription
};

const mutations = {
	RESET(state) {
		const s = getDefaultState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
	SET_ACTIVE_SUBSCRIPTION(state, subscription) {
		state.activeSubscription = subscription;
	},
	SET_SUBSCRIPTION_LIST(state, subscriptionList) {
		state.subscriptionList = subscriptionList;
	},
	REMOVE_SUBSCRIPTION_FROM_LSIT(state,subscriptionId){
		state.subscriptionList = state.subscriptionList.filter(item=>item._id !== subscriptionId)
	}
};

const actions = {
	createSubscription({ commit }, payload) {
		return services.SubscriptionPredict.createSubscription(payload)
			.then((response) => {
				commit("SET_ACTIVE_SUBSCRIPTION", response.body);
				return response
			})
			.catch((error) => {
				checkRedirectionByStatus(error.status, commit);
				commit(
					"error/SET_ERROR",
					{ status: error.status },
					{
						root: true,
					}
				);
			});
	},
	updateSubscription({ commit }, subscription) {
		const subscriptionId = subscription._id
		return services.SubscriptionPredict.updateSubscription(subscriptionId, subscription)
			.then((response) => {
				commit("SET_ACTIVE_SUBSCRIPTION", response);
				return response
			})
			.catch((error) => {
				checkRedirectionByStatus(error.status, commit);
				commit(
					"error/SET_ERROR",
					{ status: error.status },
					{
						root: true,
					}
				);
			});
	},
	retrieveSubscriptions({ commit }) {
		return services.SubscriptionPredict.retrieveSubscriptions()
			.then((response) => {
				commit("SET_SUBSCRIPTION_LIST", response);
				return response;
			})
			.catch((error) => {
				checkRedirectionByStatus(error.status, commit);
				commit(
					"error/SET_ERROR",
					{ status: error.status },
					{
						root: true,
					}
				);
			});
	},
	retrieveSubscription({ commit }, subscriptionId) {
		return services.SubscriptionPredict.retrieveSubscription(subscriptionId)
			.then((response) => {
				commit("SET_ACTIVE_SUBSCRIPTION", response);
				return response
			}).catch((error) => {
				checkRedirectionByStatus(error.status, commit);
				commit(
					"error/SET_ERROR",
					{ status: error.status },
					{
						root: true,
					}
				);
			})
	},
	deleteSubscription({ commit }, subscriptionId) {
		return services.SubscriptionPredict.deleteSubscription(subscriptionId)
			.then(() => {
				commit("SET_ACTIVE_SUBSCRIPTION", {});
				commit("REMOVE_SUBSCRIPTION_FROM_LSIT",subscriptionId)
			}).catch((error) => {
				checkRedirectionByStatus(error.status, commit);
				commit(
					"error/SET_ERROR",
					{ status: error.status },
					{
						root: true,
					}
				);
			})
	}
};

function checkRedirectionByStatus(status, commit) {
	switch (status) {
		case Utils.ERRORS.UNAUTHORIZED:
			commit("auth/SET_IS_LOGGED_IN", false, { root: true });
			router.replace({ name: "Login" }).catch(() => { });
			break;
		case Utils.ERRORS.NOT_FOUND:
		case Utils.ERRORS.METHOD_NOT_ALLOWED:
			router
				.replace({ name: "Clients", params: { showErrorDialog: true } })
				.catch(() => { });
			break;
		default:
			break;
	}
}

export default {
	namespaced: true,
	strict,
	state,
	getters,
	mutations,
	actions,
};
