import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "./plugins/bootstrap-vue";
import "./plugins/vee-validation";

import VueGtag from "vue-gtag";

import dayjs from "dayjs";
import "dayjs/locale/it";
import "dayjs/locale/en";

import i18n from "./i18n";

import BasePageTitle from "./components/Base/SimplePageTitle";
import BaseConfirmDialog from "./components/Base/BaseConfirmDialog";
import BaseLoaderDialog from "./components/Base/BaseLoaderDialog";
import BaseSuccessDialog from "./components/Base/BaseSuccessDialog";
import BaseErrorDialog from "./components/Base/BaseErrorDialog";

import Config from "./plugins/Config";

Vue.use(
  VueGtag,
  {
    config: {
      id: Config.value("dashboardGoogleAnalyticsId")
      // params: {
      //   send_page_view: false
      // }
    }
  },
  router
);

Vue.component("base-success-dialog", BaseSuccessDialog);
Vue.component("base-error-dialog", BaseErrorDialog);
Vue.component("base-confirm-dialog", BaseConfirmDialog);
Vue.component("base-loader-dialog", BaseLoaderDialog);
Vue.component("base-page-title", BasePageTitle);

Vue.config.productionTip = false;

Vue.filter("date", (date, format) => {
  const dayjsDate = dayjs(String(date));
  return dayjsDate.isValid()
    ? dayjsDate.locale(i18n.locale).format(format)
    : i18n.t("notAvailable");
});

Vue.filter("price", function(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("it-IT", {
    currency: "EUR",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});

Vue.filter("date", (date, format) => {
  const dayjsDate = dayjs(String(date));
  return dayjsDate.isValid()
    ? dayjsDate.locale(i18n.locale).format(format)
    : i18n.t("notAvailable");
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
