<template>
  <div class="d-flex title-wrapper align-items-end text-white px-4 pb-4">
    <transition name="fade">
      <div v-if="show" class="align-items-end">
        {{ $t(titleText) }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    titleText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: false
    };
  },
  created() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.show = true;
      });
    }, 10);
  }
};
</script>

<style scoped>
.title-wrapper {
  height: 120px;
  background-color: #021d31;
  font-size: 2rem;
  font-weight: 300;
}
.fade-enter-active {
  animation: fade-in-down 0.7s;
}
.fade-leave-active {
  animation: fade-in-down 0.5s reverse;
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
</style>
