import swaggerClient from "./../plugins/swagger-client";

export default class UserService {
  loginUser(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.loginUser(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  
  retrieveUser(userId) {
    return swaggerClient.swagger
    .then(client => {
      return client.apis.users.retrieveUser(
          { userId },
          swaggerClient.buildRequestBody(),
          {}
          );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
    }
  
  retrieveUsers({ limit, skip, clientId, subType } = {}) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.retrieveUsers(
          { limit, skip, clientId, subType },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createUser(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.createUser(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateUser(userId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.updateUser(
          { userId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteUser(userId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.deleteUser(
          { userId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  confirmUser(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.confirmUser(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(() => {})
      .catch(error => {
        return Promise.reject(error);
      });
  }

  recoverUserPassword(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.recoverUserPassword(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(() => {})
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
